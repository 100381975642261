import React from "react";

const imageIndex = Math.random().toFixed(0);
export default function Splash(props) {
    const opacity = props.splashTimeLeft / props.splashTime;


    return (
        <div className="splash-wrap" style={{ backgroundImage: `url('/images/splash${imageIndex}.jpg')`, opacity }}>
            <div className="container">
                <div className="row splash-row">
                    <div className="Quote col-sm-offset-3 col-sm-6 col-md-offset-2 col-md-8">
                        Wheelie Ben
                    </div>
                </div>
            </div>
        </div>
    )
}
