import React from 'react';
import Nav from "./Nav";
import PropTypes from 'prop-types';

export default function Header(props) {

    return (
        <>
            <div className={`nav-toggle page-furniture ${props.navOpen ? 'expanded' : ''}`} onClick={props.toggleNav}>
                <div className="nav-toggle-bar"></div>
            </div>

            <Nav navOpen={props.navOpen} toggleNav={props.toggleNav} page={props.page} />

            <div className="logo">
                <div>
                    <img src="/images/logo-shadow.png" alt="Wheelie Ben"/>
                </div>

            </div>
        </>
    );
}

Header.propTypes = {
    navOpen: PropTypes.bool.isRequired,
    toggleNav: PropTypes.func.isRequired,
    page: PropTypes.string.isRequired
}