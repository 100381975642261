import React, {useCallback, useEffect, useState} from 'react';
import Header from "./Header";
import parse from "html-react-parser";
import allQuotes from "./quotes.json";
import {useSwipeable} from "react-swipeable";
import PropTypes from "prop-types";
import Splash from "./Splash";

const shuffle = (list) => {
    return list.sort(() => Math.random() - 0.5);
}

const shuffledQuotes = shuffle(allQuotes);

const backgroundsCount = 15;
const slideDurationSeconds = 12;

let splashDurationMilliseconds = 2000;

const okQuotes = [];

shuffledQuotes.forEach((quote) => {
    okQuotes.push(quote);
});

export default function Home(props) {

    const [quotes] = useState(okQuotes);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [paused, setPaused] = useState(false);
    const [splashFinished, setSplashFinished] = useState(false);
    const [splashTimeLeft, setSplashTimeLeft] = useState(splashDurationMilliseconds);
    const [currentSlideTimeLeft, setCurrentSlideTimeLeft] = useState(slideDurationSeconds);

    const complete = currentIndex / okQuotes.length * 100;

    const nextQuote = useCallback(() => {
        setCurrentSlideTimeLeft(slideDurationSeconds);
        setCurrentIndex((prev) => {
            let nextIndex;
            if (prev === (quotes.length -1)){
                nextIndex = 0;
            } else {
                nextIndex = prev + 1;
            }
            return nextIndex;
        });
    }, [quotes]);

    const prevQuote = useCallback(() => {
        setCurrentIndex((prev) => {
            let nextIndex;
            if (prev === 0){
                nextIndex = quotes.length - 1;
            } else {
                nextIndex = prev - 1;
            }
            return nextIndex;
        });
    }, [quotes]);

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.velocity < 0.5){
                return;
            }
            if (eventData.dir === 'Left'){
                nextQuote();
            } else {
                prevQuote();
            }
        },
        onTouchStartOrOnMouseDown: () => {
            setPaused(true);
        },
        onTouchEndOrOnMouseUp: () => {
            setPaused(false);
        }
    });

    useEffect(() => {
        if (paused){
            return;
        }
        let needToClear = false;
        const interval = setInterval(() => {
            if (currentSlideTimeLeft <= 0){
                setCurrentSlideTimeLeft(slideDurationSeconds);
                nextQuote();
                needToClear = true;
            } else {
                setCurrentSlideTimeLeft(prev => {
                    return prev - (0.050);
                })
            }
        }, 50);
        if (needToClear){
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        }
    }, [paused, currentIndex, currentSlideTimeLeft, nextQuote]);

    useEffect(() => {
        const onKeyPress = (e) => {
            if (e.key === 'ArrowRight'){
                nextQuote();
            }
            if (e.key === 'ArrowLeft'){
                prevQuote();
            }
            if (e.key === ' ') {
                setPaused(prev => !prev);
            }
        }
        document.addEventListener('keydown', onKeyPress);
        return () => {
            document.removeEventListener('keydown', onKeyPress);
        }
    }, [nextQuote, prevQuote]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSplashTimeLeft((prev) => {
                if (prev > 0){
                    return prev - 50;
                } else {
                    setSplashFinished(true);
                }
            });
        }, 50);

        return () => {
            clearInterval(interval);
        }
    }, []);

    if (splashFinished === false){
        return <Splash splashTime={splashDurationMilliseconds} splashTimeLeft={splashTimeLeft} />;
    }

    const invert = false;

    const secondsLeft = Math.max(currentSlideTimeLeft, 0);

    return (
        <div className="wrap" style={{ backgroundImage: `url('/images/bg-${(currentIndex % backgroundsCount) + 1}.jpg')` }}>

            <Header navOpen={props.navOpen} toggleNav={props.toggleNav} page="Home" />
            <div className={invert ? 'container invert' : 'container'}>
                <div className="row d-flex flex-column justify-content-center align-items-center quote-row" {...handlers}>
                    <div className="Quote col-sm-offset-3 col-sm-6 col-md-offset-2 col-md-8 quote">
                        {parse(quotes[currentIndex])}
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-12 pause-button" style={{display: paused ? 'block' : 'none', textAlign: 'center'}}>
                        <img src="/images/pause-icon.png" alt="Paused" className="pause-icon"/>
                    </div>
                </div>
            </div>

            <p className="quote-count page-furniture">
                {secondsLeft.toFixed(0)}
                <br />
                {`${complete.toFixed(0)}%`}
                <br/>
                    {`${currentIndex}/${quotes.length}`}
                <br/>
            </p>
        </div>
    );
}

Home.propTypes = {
    navOpen: PropTypes.bool.isRequired,
    toggleNav: PropTypes.func.isRequired
}