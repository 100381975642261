import './Nav.css';
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

export default function Nav(props) {

    return (
        <nav id="nav" className={`nav ${props.navOpen ? 'expanded' : ''}`}>
            <ul>
                <li className={props.page === 'Home' ? 'active' : ''}><Link to="/" onClick={props.toggleNav}>Home</Link></li>
                <li className={props.page === 'Videos' ? 'active' : ''}><Link to="/videos/" onClick={props.toggleNav}>Videos</Link></li>
                <li className=""><a href="https://shop.wheelieben.co.uk">Shop</a></li>
            </ul>
        </nav>
    );
}

Nav.propTypes = {
    navOpen: PropTypes.bool.isRequired
};