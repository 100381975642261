import './Videos.css';
import React from 'react';
import Header from "./Header";
import PropTypes from "prop-types";

import videos from './videos.json';
import parse from "html-react-parser";

export default function Videos(props) {

    document.title = 'Videos of wheelieben!'
    document.description = 'Peep the mind of Ben!'

    return (
        <div className="wrap scroll-y">
            <Header navOpen={props.navOpen} toggleNav={props.toggleNav} page="Videos" />
            <div className="container">
                <div className="row d-flex flex-column justify-content-center align-items-center video-row">
                    <div className="col-12 quote">
                        <h2>Videos</h2>

                        <div id="video-container">
                            {Object.entries(videos).map(([key, value]) => {
                                return (
                                    <div key={key}>
                                        <h2 className="video-title" id={key}>{parse(key)}</h2>
                                        <div className='embed-container'>
                                            <iframe title={key} src={value} frameBorder='0' allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Videos.propTypes = {
    navOpen: PropTypes.bool.isRequired,
    toggleNav: PropTypes.func.isRequired
}