import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import {
    Routes,
    Route, BrowserRouter
} from "react-router-dom";

import Home from "./Home";
import Videos from "./Videos";
import {useState} from "react";

function App() {

    const [navOpen, setNavOpen] = useState(false);

    const toggleNav = () => {
        setNavOpen((prev) => {
            return !prev;
        })
    }

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home navOpen={navOpen} toggleNav={toggleNav} />} />
            <Route path="videos" element={<Videos navOpen={navOpen} toggleNav={toggleNav} />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
